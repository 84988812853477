import React from "react";

import "./followUsBanner.css"
import firstImage from "../../assets/home/bottom_senior_students_outside_church.jpg"

const FollowUsBanner = () => (
    <div className="justify-content-center followUsBannerContainer">
        <img src={firstImage} className="img-fluid justify-content-center " height={600} alt="..."/>
    </div>
)

export default FollowUsBanner
